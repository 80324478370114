import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { CircularProgress, ThemeProvider } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-core'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'

const Home = lazy(() => import('../pages/Home'))

enum ROUTES {
    HOME = '/'
}

const Router: React.FC = () => {
    const theme = useTheme()
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Suspense fallback={<CircularProgress />}>
                    <Switch>
                        <ProtectedRoute path={ROUTES.HOME} component={Home} />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
