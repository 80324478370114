import { Provider } from 'react-redux'
import Router from './routes'
import { store, ThriveApplication } from '@thriveglobal/thrive-web-core'

export default function Root(props) {
    return (
        <ThriveApplication
            apolloClientOptions={{
                cacheOptions: {},
                name: 'thrive-web-wearables'
            }}
            sentryDsn={process.env.SENTRY_DSN}
        >
            <Provider store={store}>
                <section>
                    <Router />
                </section>
            </Provider>
        </ThriveApplication>
    )
}
